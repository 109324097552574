import {ref, onMounted, defineComponent} from "vue";
import { IonPage, IonRippleEffect} from "@ionic/vue";
import { useRouter } from "vue-router";
import {
    Preferences,
    savePreferences
} from "@/storage/DataStorage";
import translate from "../translate";
import {useStore} from "vuex";
import {checkIsMobileDevice, checkStillLocked, setCalcLock} from "@/views/Calculator/LockingCommon";
import {openAlert, showEmergencyAlertAndNavigate} from "@/views/Calculator/CalculatorCommon";
import {checkForJourneySetImport} from "@/utilities/JourneySet";
import {deleteApp} from "@/utilities/Filesystem";
import {DeleteApp} from '@/delete-app-plugin/definitions';

export default defineComponent({
    name: "CalculatorAlt",
    components: {
        IonPage,
        IonRippleEffect,
    },
    async ionViewWillEnter() {
        await this.store.dispatch("setNeedToSetPin", true);
        this.preferences = this.store.getters.getPreferences();
        const shouldSetPin = this.preferences.shouldSetPin;
        const messages = this.store.getters.getLocaleMessages();
        if (shouldSetPin) {
            this.displayText = messages.calculator.createPINAlternateMessage;
        } else {
            this.displayText = "";
        }
    },
    setup() {
        const store = useStore();
        const { t } = translate();
        const displayText = ref("");
        const pin = ref([] as string[]);
        const validatePin = ref([] as string[]);
        const router = useRouter();
        const preferences = ref({} as Preferences);
        const stillLocked = ref(false);
        const isMobileDevice = ref(false);
        const isiOSDevice = ref(false);
        
        onMounted(async () => {
            isMobileDevice.value = await checkIsMobileDevice();
            isiOSDevice.value = store.getters.platform() === "ios";
            
            try {
                preferences.value = store.getters.getPreferences();
                const {shouldSetPin} = preferences.value;
                if (shouldSetPin) {
                    displayText.value = t("messages.calculator.createPINAlternateMessage");
                }
            } catch {
                return;
            }
        });
        
        const cleanUp = () => {
            pin.value = [];
            validatePin.value = [];
        }
        
        const setPin = async () => {
            if (pin.value.length === 4 && validatePin.value.length === 0) {
                validatePin.value = [...pin.value];
                pin.value = [];
                displayText.value = t("messages.calculator.reenterPinAlternateMessage");
            } else if (validatePin.value.length === 4 && validatePin.value.join("") === pin.value.join("")) {
                preferences.value.entrancePin = pin.value.join("");
                preferences.value.shouldSetPin = false;
                await savePreferences(preferences.value);
                cleanUp();
                await openAlert(
                    `<p>${t("messages.calculator.setPINMessage")}</p> <p>${t("messages.calculator.usePINAlternateMessage")}</p>`,
                    `${t("messages.calculator.next")}`,
                    () => showEmergencyAlertAndNavigate(preferences.value.lastVisitedRoute, "messages.calculator.emergencyPINToUseAlternateMessage", t)                   
                );
            } else if (validatePin.value.length === 4 && validatePin.value.join("") !== pin.value.join("")) {
                cleanUp();
                displayText.value = t("messages.calculator.failedPinResetMessage") + " \n\n " + t("messages.calculator.tryAgainMessage");
            }
        };

        const setPinLock = async (preferences: Preferences, message: string) => {
            await setCalcLock(preferences);
            displayText.value = t(message);
        }
        
        const evaluate = async () => {
            if (isMobileDevice.value) {
                stillLocked.value = await checkStillLocked(preferences.value);
            }
            
            if (!isiOSDevice.value && pin.value.join("") === preferences.value.deletePin) {
                cleanUp();
                if (isMobileDevice.value) {
                    deleteApp();
                } else {
                    DeleteApp.deleteApp();
                }
            } else if (!stillLocked.value && pin.value.join("") === preferences.value.entrancePin && preferences.value.canAccessApp) {
                await store.dispatch("clearLockoutDateTime");
                await store.dispatch("clearFailedAttempts");
                await store.dispatch("setNeedToSetPin", false);
                cleanUp();
                await router.push({ path: `${preferences.value.lastVisitedRoute}` });
                await checkForJourneySetImport();
            }
            else {
                pin.value = [];
                if (isMobileDevice.value) {
                    if (!stillLocked.value) {
                        await store.dispatch("incrementFailedAttempts");
                        const failedAttempts = store.getters.getFailedAttempts();
                        if (failedAttempts === 5) {
                            await store.dispatch("setLockoutDateTime");
                            await setPinLock(preferences.value, "messages.calculator.tryAgainTomorrow");
                        } else if (failedAttempts === 20) {
                            await setPinLock(preferences.value, "messages.calculator.reinstall");
                        } else {
                            displayText.value = t("messages.calculator.tryAgainMessage");
                        }
                        return;
                    }
                    displayText.value = t("messages.calculator.tryAgainTomorrow");
                } else {
                    displayText.value = t("messages.calculator.tryAgainMessage");
                }
            }
                
        };

        const append = async (number: string) => {
            if (pin.value.length < 4)
                pin.value = [...pin.value, number];
            if (pin.value.length === 4) {
                if (preferences.value.shouldSetPin) {
                    await setPin();
                } else {
                    await evaluate();
                }
            }
        };
        
        const deleteOne = () => {
            if (pin.value.length > 0) {
                pin.value.pop();
            }
        };

        return {
            t,
            displayText,
            pin,
            deleteOne,
            append,
            preferences,
            store,
            stillLocked
        };
    },
});
