import {Preferences, savePreferences} from "@/storage/DataStorage";
import {store} from "@/store";
const LOCKOUT_TIME_IN_HOURS = 6;

export const checkStillLocked = async (preferences: Preferences): Promise<boolean> => {
    const lockoutDateTime = store.getters.getLockoutDateTime();
    if (!lockoutDateTime) {
        return false;
    }
    
    const now = new Date();
    const stillLocked = lockoutDateTime.getTime() >= now.setHours(now.getHours() - LOCKOUT_TIME_IN_HOURS);

    if (!stillLocked) {
        await store.dispatch("clearLockoutDateTime");
        preferences.canAccessApp = true;
        await savePreferences(preferences);
    }

    return stillLocked;
}

export const checkIsMobileDevice = async (): Promise<boolean> => {
    const platform = await store.getters.platform();
    return platform !== "web";
}

export const setCalcLock = async (preferences: Preferences) => {
    preferences.canAccessApp = false;
    await savePreferences(preferences);
}